import React from "react"
import Fade from "react-reveal/Fade"
import timicon from '../images/timicon.png'
import caylaicon from '../images/caylaicon.png'
import Layout from "../components/layout"
import Connect from "../components/connect"
import "../styles/mains.scss"

const About = () => {
  return (
    <Layout>
      <div className="section" id="about">
        <div className="container">
          <div className="about">
            <Fade bottom cascade>
              <h1>Meet{'\n'} the Team</h1>
              <div className="item-container">
                <div className="item">
                  <div className="image-wrapper">
                    <img className="image" src={timicon} alt="about"></img>
                    <Connect person="TIM" />
                  </div>
                  <div className="content">
                    <p>
                      Hi! I’m Tim, a 4th year Computer Science major at Georgia Tech. This
                      project was started because I love discovering new places to eat
                      and wanted to create a platform where food lovers can find and share
                      delicious eats. Gathering to eat food is a social tradition that builds
                      and strengthens communities, and I am hopeful that Feast will be an
                      important part of that.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p className="bottom-text">
                  Happy Feasting :)
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About