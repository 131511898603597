import React from "react"
import Fade from "react-reveal/Fade"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const TIM_LINKEDIN_URL = "https://www.linkedin.com/in/timtan1/";
const TIM_EMAIL = "timmytan18@gmail.com";
const CAYLA_LINKEDIN_URL = "https://www.linkedin.com/in/cayladavis2185/";
const CAYLA_EMAIL = "cayla@feastapp.io";

const Connect = ({ person }) => {
  const email = person === "TIM" ? TIM_EMAIL : CAYLA_EMAIL;
  const linkedin = person === "TIM" ? TIM_LINKEDIN_URL : CAYLA_LINKEDIN_URL;
  return (
    <Fade bottom>
      <div className="social-media-div">
        <a href={`mailto: ${email}`} className="mail" target="_blank">
          <div className="icon-button"><FontAwesomeIcon icon={faEnvelope} color="white" /></div>
        </a>
        <a href={linkedin} className="linkedin" target="_blank">
          <div className="icon-button"><FontAwesomeIcon icon={faLinkedinIn} color="white" /></div>
        </a>
      </div>
    </Fade>
  );
}

export default Connect